import './navbar';

/* global algoliasearch instantsearch */

const filtersEl = document.getElementById('filters-container');
window.toggleFilters = function() {
  filtersEl.classList.toggle('expanded');
}

const pageURL = window.location.href;
let language = pageURL.substr(pageURL.lastIndexOf('/') + 1);
if (!language) {
  language = 'en';
}

const searchClient = algoliasearch(
  process.env.APPLICATION_ID,
  process.env.API_KEY
);

const search = instantsearch({
  indexName: process.env.ALGOLIA_INDEX,
  searchClient,
});

search.addWidgets([
  instantsearch.widgets.searchBox({
    container: '#searchbox',
  }),
  instantsearch.widgets.stats({
    container: '#search-stats',
  }),
  instantsearch.widgets.hits({
    container: '#hits',
    cssClasses: {
      list: 'products-list',
      item: 'product',
    },
    templates: {
      item: `
<a href ="product?object={{objectID}}">
<article>
  <div class="product-image-container">
    <img class="product-image" src={{image_url}} />
  </div>
  <div class="content">
    <p class="producttype">{{#helpers.highlight}}{ "attribute": "type_of_product" }{{/helpers.highlight}}</p>
    <p class="brand">{{#helpers.highlight}}{ "attribute": "brand" }{{/helpers.highlight}}</p>
    <p class="manufacturer">{{manufacturer}}</p>
    <p class="price">{{price}}</p>
  </div>
</article>
</a>
`,
      empty: `
<p class='info'>No results were found with your current filters. Try to remove some filters or change the search query.</p>
`,
    },
  }),
  instantsearch.widgets.refinementList({
    container: '#by-category',
    attribute: 'category',
  }),
  instantsearch.widgets.numericMenu({
    container: '#by-price',
    attribute: 'price',
    items: [
      { label: 'All' },
      { label: 'Less than 5000 L.L.', end: 5000 },
      { label: 'Between 5000 L.L. - 10000 L.L. ', start: 5000, end: 10000 },
      { label: 'Between 10000 L.L. - 20000 L.L. ', start: 10000, end: 20000 },
      { label: 'Between 20000 L.L. - 50000 L.L. ', start: 20000, end: 50000 },
      { label: 'More than 50000 L.L.', start: 50000 },
    ],
  }),
  instantsearch.widgets.pagination({
    container: '#pagination',
  }),
]);

search.start();
